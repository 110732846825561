import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'

import AContainer from '../components/01-atoms/AContainer'
import AType from '../components/01-atoms/AType'

import MOpeningHours from '../components/02-molecules/MOpeningHours'

import OIntro from '../components/03-organisms/OIntro'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Termin vereinbaren</title>
    </Helmet>

    <OIntro
      title={
        <AType type="h1" className="title">
          Termin vereinbaren
        </AType>
      }
      subtitle={
        <AType className="subtitle">
          Optique – Meisterhandwerk und Brillenmanufaktur
          <br /> in Stuttgart-Süd
        </AType>
      }
    />

    <AContainer>
      <MOpeningHours />

      <div>
        <iframe
          title="Termine"
          src="https://optique-stuttgart.youcanbook.me/?noframe=true&skipHeaderFooter=true"
          id="optique-stuttgart"
          style={{
            position: 'relative',
            'z-index': '0',
            width: '100%',
            height: '1000px',
            border: '0px',
            'background-color': 'transparent',
          }}
          frameBorder="0"
          allowTransparency="true"
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              'window.addEventListener && window.addEventListener("message", function(event){if (event.origin === "https://optique-stuttgart.youcanbook.me"){document.getElementById("optique-stuttgart").style.height = event.data + "px";}}, false);',
          }}
        />
      </div>
    </AContainer>
  </Layout>
)

export default IndexPage
