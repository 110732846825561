import React from 'react'

import AOpeningHours from '../01-atoms/AOpeningHours'
import ASection from '../01-atoms/ASection'

import styles from './MOpeningHours.module.scss'

const MOpeningHours = () => (
  <ASection className={styles.section} type="secondary">
    <AOpeningHours />
  </ASection>
)

export default MOpeningHours
